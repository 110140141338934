<template>
  <div :class="['custom-table', isChooseList ? 'choose' : '']">
    <div v-if="data.length" class="custom-table__container">
      <div class="custom-table__header" :style="getRowStyle">
        <div v-if="isNumbered" class="custom-table__header-item custom-table__number-of-item">
          #
        </div>
        <div
          :class="[
            'custom-table__header-item',
            !isElementShowed(columnName) && 'custom-table__none-item',
          ]"
          v-for="columnName in Object.keys(prepareData[0])"
          :key="columnName.id"
        >
          <div class="custom-table__header-item--container" v-if="isElementShowed(columnName)">
            <div class="custom-table__header-item--text">{{ prepareColumnName(columnName) }}</div>
            <div class="custom-table__filter" @click="handleFilter(columnName)">
              <div
                :class="[
                  'custom-table__filter--asc custom-table__filter-item',
                  columnName == order && direction === true ? 'active' : '',
                ]"
              >
                <FilterIcon />
              </div>
              <div
                :class="[
                  'custom-table__filter--desc custom-table__filter-item',
                  columnName == order && direction === false ? 'active' : '',
                ]"
              >
                <FilterIcon />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isActionBlockVisible"
          class="custom-table__action-block"
          :style="{ width: actionHeaderBlockWidth }"
        ></div>
        <div class="custom-table__checkbox">
          <InputCheckboxMain
            v-if="setDriverId"
            :id="'all'"
            :handleGetId="setDriverId"
            :arrayId="driverChooseAll"
          />
        </div>
      </div>
      <div class="custom-table__body">
        <div
          v-for="(row, index) in prepareData"
          :key="index"
          class="custom-table__row"
          :style="getRowStyle"
          @click="handleChoose ? handleChoose(row) : () => {}"
        >
          <div v-if="isNumbered" class="custom-table__row-item custom-table__number-of-item">
            {{ index + 1 }}
          </div>
          <div
            v-for="(item, key) in row"
            :key="key"
            @click="
              onActionColumn.some((column) => column.columnName === key)
                ? handleActionOnClick(
                    onActionColumn.find((column) => column.columnName === key),
                    row,
                  )
                : booleanSwitcher.some((column) => column.columnName === key)
                ? handleActionOnClick(
                    booleanSwitcher.find((column) => column.columnName === key),
                    row,
                  )
                : () => {}
            "
            :class="[
              'custom-table__row-item',
              !isElementShowed(key) && 'custom-table__none-item',
              onCustomStyleColumn.some((column) => column.columnName === key) &&
                onCustomStyleColumn.find((column) => column.columnName === key).className,
            ]"
          >
            <template v-if="isElementShowed(key)">
              <div v-if="isImage(key)">
                <img :src="item" />
              </div>
              <template v-else-if="isSwitcher(key)">
                <div :class="[item ? 'switcher' : 'switcher switcher--disabled']">
                  {{ item ? "yes" : "no" }}
                </div>
              </template>
              <div
                v-else-if="key === 'status' && name === 'order'"
                :class="[
                  item.key === 'completed' ||
                  item.key === 'completed_by_driver' ||
                  item.key === 'completed_by_client' ||
                  item.key === 'completed_time_out' ||
                  item.key === 'completed_by_dispatcher'
                    ? ''
                    : item.key === 'placed' ||
                      item.key === 'active' ||
                      item.key === 'set' ||
                      item.key === 'today' ||
                      item.key === 'suggesting' ||
                      item.key === 'waiting_dispatcher' ||
                      item.key === 'moderated_dispatcher'
                    ? 'green'
                    : 'red',
                ]"
              >
                {{ item.title }}
              </div>
              <div
                v-else-if="key === 'free' && name === 'driver'"
                :class="[item ? 'green' : 'red']"
              >
                {{ item ? "Свободен" : "Занят" }}
              </div>
              <div v-else-if="key === 'driver_last_name' && name === 'order'">
                <router-link class="custom-table__link" :to="`/driverDetail/${row.driver_id}`">{{
                  item
                }}</router-link>
              </div>
              <div v-else>{{ item }}</div>
            </template>
          </div>
          <div v-if="isActionBlockVisible" class="custom-table__action-block" ref="actionBlock">
            <div
              v-if="
                handleAssignDriver &&
                (row.status.key === 'placed' ||
                  row.status.key === 'set' ||
                  row.status.key === 'today' ||
                  row.status.key === 'suggesting')
              "
              class="custom-table__icon"
            >
              <Driver @click="handleAssignDriver(row)" />
            </div>
            <div v-if="handleCopy" class="custom-table__icon">
              <Eye @click="handleCopy(row)" />
            </div>
            <div
              v-if="
                name == 'order'
                  ? handleEdit &&
                    (row.status.key === 'placed' ||
                      row.status.key === 'active' ||
                      row.status.key === 'set' ||
                      row.status.key === 'today' ||
                      row.status.key === 'suggesting' ||
                      row.status.key === 'expired' ||
                      row.status.key === 'waiting_dispatcher' ||
                      row.status.key === 'moderated_dispatcher')
                  : handleEdit && ifHandleEdit
              "
              class="custom-table__icon"
            >
              <Pencil @click="handleEdit(row)" />
            </div>
            <div v-if="handleDelete && ifHandleDelete" class="custom-table__icon">
              <Basket @click="handleDelete(row)" />
            </div>
            <div
              v-if="
                name == 'order'
                  ? handleCancel &&
                    (row.status.key === 'placed' ||
                      row.status.key === 'active' ||
                      row.status.key === 'set' ||
                      row.status.key === 'today' ||
                      row.status.key === 'suggesting' ||
                      row.status.key === 'waiting_dispatcher' ||
                      row.status.key === 'moderated_dispatcher')
                  : handleCancel
              "
              class="custom-table__icon"
            >
              <Cancel @click="handleCancel(row)" />
            </div>
            <div v-if="handleRestore && ifHandleRestore" class="custom-table__icon">
              <Restore @click="handleRestore(row)" />
            </div>
            <div v-if="handleButton" class="custom-table__button">
              <ButtonMain @click.native="handleButton(row)">Назначить</ButtonMain>
              <InputCheckboxMain
                v-if="setDriverId"
                :id="row.id"
                :handleGetId="setDriverId"
                :arrayId="driverChoose"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Basket from "../../assets/images/icons/basket.svg";
import Pencil from "../../assets/images/icons/pencil.svg";
import Eye from "../../assets/images/icons/eye.svg";
import FilterIcon from "../../assets/images/icons/filter.svg";
import Cancel from "../../assets/images/icons/cancel-icon.svg";
import ButtonMain from "../atoms/buttons/ButtonMain.vue";
import Driver from "../../assets/images/icons/driver.svg";
import Restore from "../../assets/images/icons/restotre.svg";
import InputCheckboxMain from "../atoms/inputs/InputCheckboxMain.vue";

export default {
  name: "CustomTable",
  props: {
    data: {
      type: [Array],
      default() {
        return [];
      },
    },
    setDriverId: {
      type: [Function, Boolean],
      default: false,
    },
    handleRestore: {
      type: [Function, Boolean],
      default: false,
    },
    ifHandleRestore: {
      type: Boolean,
      default: false,
    },
    handleFilter: {
      type: [Function, Boolean],
      default: null,
    },
    handleEdit: {
      type: [Function, Boolean],
      default: null,
    },
    ifHandleEdit: {
      type: Boolean,
      default: true,
    },
    handleDelete: {
      type: [Function, Boolean],
      default: null,
    },
    ifHandleDelete: {
      type: Boolean,
      default: true,
    },
    handleCopy: {
      type: [Function, Boolean],
      default: null,
    },
    handleCancel: {
      type: [Function, Boolean],
      default: null,
    },
    handleButton: {
      type: [Function, Boolean],
      default: null,
    },
    handleAssignDriver: {
      type: [Function, Boolean],
      default: null,
    },
    onActionColumn: {
      type: [Array],
      default() {
        return [];
      },
    },
    onCustomStyleColumn: {
      type: [Array],
      default() {
        return [];
      },
    },
    customOrder: {
      type: [Array, Boolean],
      default: null,
    },
    isNumbered: {
      type: Boolean,
      default: false,
    },
    isActionBlockVisible: {
      type: Boolean,
      default: false,
    },
    isChooseList: {
      type: Boolean,
      default: false,
    },
    handleChoose: {
      type: [Function, Boolean],
      default: false,
    },
    deletedKeys: {
      type: [Array],
      default() {
        return [];
      },
    },
    booleanSwitcher: {
      type: [Array],
      default() {
        return [];
      },
    },
    images: {
      type: [Array],
      default() {
        return [];
      },
    },
    renameColumns: {
      type: [Array],
      default() {
        return [];
      },
    },
    direction: {
      type: Boolean,
      default: null,
    },
    order: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  components: {
    Basket,
    Pencil,
    Eye,
    FilterIcon,
    Cancel,
    Driver,
    ButtonMain,
    Restore,
    InputCheckboxMain,
  },
  data() {
    return {
      actionHeaderBlockWidth: "auto",
    };
  },
  computed: {
    ...mapState("common", ["driverChoose", "driverChooseAll"]),
    prepareData() {
      const { data } = this;
      const preparedData = data.map((item) => {
        if (this.customOrder?.length) {
          const preparedItem = {};
          this.customOrder.map((key) => {
            preparedItem[key] = item[key];
            return true;
          });
          return preparedItem;
        }

        return item;
      });
      return preparedData;
    },
    getRowStyle() {
      const getAvalibleLength = Object.keys(this.prepareData[0]).length;
      if (!getAvalibleLength) return false;
      const totalLength = getAvalibleLength - this.deletedKeys.length;
      const gridArray = Array(totalLength).fill("1fr").join(" ");
      return {
        display: "grid",
        "grid-template-columns": `${gridArray} ${this.isNumbered ? "1fr" : ""} ${
          this.onActionColumn ? "1fr" : ""
        }`,
      };
    },
  },
  // mounted() {
  //   this.$nextTick(() => {
  // this.actionHeaderBlockWidth = `${this.$refs.actionBlock[0]?.getBoundingClientRect().width}px`;
  //   });
  // },
  methods: {
    prepareColumnName(name) {
      if (this.renameColumns.length) {
        let newName = name;
        this.renameColumns.forEach((item) => {
          if (item.from === name) {
            newName = item.to;
          }
        });
        return newName;
      }
      return name;
    },
    isElementShowed(element) {
      if (this.deletedKeys.length && typeof element === "string") {
        return !this.deletedKeys.some((el) => el.toLowerCase() === element.toLowerCase());
      }
      return false;
    },
    isImage(element) {
      if (this.images.length && typeof element === "string") {
        return this.images.some((el) => el.toLowerCase() === element.toLowerCase());
      }
      return false;
    },
    isSwitcher(element) {
      if (this.booleanSwitcher.length && typeof element === "string") {
        return this.booleanSwitcher.some(
          (el) => el.columnName.toLowerCase() === element.toLowerCase(),
        );
      }
      return false;
    },
    handleActionOnClick(item, row) {
      item.action(row);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-table {
  min-width: 100%;
  background: $g-color-white;

  &__header {
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px 16px;
    position: relative;
    background: $mainColor;
  }

  &__checkbox {
    position: absolute;
    top: 20px;
    right: 16px;

    /deep/.checkbox-input__checkbox {
      background: #fff;

      svg {
        path {
          fill: $mainColor;
        }
      }
    }
  }

  &__container {
    margin-top: 20px;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
  }

  &__header-item {
    display: flex;
    align-items: center;
    padding-right: 5px;
    width: 100%;
    font-size: 12px;
    color: $g-color-white;
    text-align: left;
  }

  &__header-item--text {
    display: flex;
    align-items: center;
    width: min-content;
    word-wrap: break-word;
  }

  &__header-item--container {
    @include flex-row;

    justify-content: center;
  }

  &__filter {
    @include flex-column;

    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 5px;

    &-item {
      height: 6px;
      width: 10px;
    }

    &-item.active {
      svg {
        path {
          fill: $mainSecondColor;
        }
      }
    }
  }

  &__filter--asc {
    position: relative;
    transform: rotate(180deg);
    top: 4px;
  }

  &__filter--desc {
    position: relative;
    left: -0.03rem;
    top: -4px;
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /deep/.checkbox-input__wrap {
      margin-left: 20px;
    }

    /deep/.button-main {
      width: 136px;
      height: 36px;
    }
  }
  &__row {
    display: flex;
    width: 100%;
    padding: 16px 16px;
    position: relative;
    align-items: right;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #dbdbdb;
      bottom: 0;
    }
  }

  &__link {
    color: $mainColor;

    &:hover {
      text-decoration: underline;
    }
  }

  &__row.choose {
    cursor: pointer;

    &:hover {
      background: $mainColor;

      .custom-table__row-item {
        color: $g-color-white;
      }
    }
  }

  &__row-item {
    width: 100%;
    font-size: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    color: $mainTextColor;
    padding-right: 5px;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__none-item {
    display: none;
  }

  &__number-of-item {
    width: 15%;
  }

  &__action-block {
    display: flex;
    width: 100%;
    justify-content: space-around;
    position: relative;
  }

  .switcher {
    text-align: center;
    width: 100%;
    background: #ff4d4d;
    border-radius: 25px;
    padding: 4px 0;
    cursor: pointer;
    margin: 5px;

    &--disabled {
      background: #c4c4c4;
    }
  }

  &__icon {
    display: flex;
    svg {
      margin: auto 0;
      cursor: pointer;
      path {
        fill: $mainColor;
      }

      rect {
        fill: $mainColor;
      }
    }
  }
  &__icon:hover {
    path {
      fill: $mainSecondColor;
    }

    rect {
      fill: $mainSecondColor;
    }
  }
  &__icon:active {
    path {
      fill: #e4c201;
    }

    rect {
      fill: #e4c201;
    }
  }
}

.custom-table.choose {
  .custom-table__container {
    margin-top: 0;
    margin-bottom: 2px;
  }
  .custom-table__row {
    cursor: pointer;

    &:hover {
      background: $mainColor;

      .custom-table__row-item {
        color: $g-color-white;
      }
    }
  }
}
.red {
  color: #eb5959;
}
.green {
  color: #5fbf68;
}
/deep/.custom-table__icon {
  margin-right: 15px;
}
</style>
