var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['custom-table', _vm.isChooseList ? 'choose' : '']},[(_vm.data.length)?_c('div',{staticClass:"custom-table__container"},[_c('div',{staticClass:"custom-table__header",style:(_vm.getRowStyle)},[(_vm.isNumbered)?_c('div',{staticClass:"custom-table__header-item custom-table__number-of-item"},[_vm._v(" # ")]):_vm._e(),_vm._l((Object.keys(_vm.prepareData[0])),function(columnName){return _c('div',{key:columnName.id,class:[
          'custom-table__header-item',
          !_vm.isElementShowed(columnName) && 'custom-table__none-item' ]},[(_vm.isElementShowed(columnName))?_c('div',{staticClass:"custom-table__header-item--container"},[_c('div',{staticClass:"custom-table__header-item--text"},[_vm._v(_vm._s(_vm.prepareColumnName(columnName)))]),_c('div',{staticClass:"custom-table__filter",on:{"click":function($event){return _vm.handleFilter(columnName)}}},[_c('div',{class:[
                'custom-table__filter--asc custom-table__filter-item',
                columnName == _vm.order && _vm.direction === true ? 'active' : '' ]},[_c('FilterIcon')],1),_c('div',{class:[
                'custom-table__filter--desc custom-table__filter-item',
                columnName == _vm.order && _vm.direction === false ? 'active' : '' ]},[_c('FilterIcon')],1)])]):_vm._e()])}),(_vm.isActionBlockVisible)?_c('div',{staticClass:"custom-table__action-block",style:({ width: _vm.actionHeaderBlockWidth })}):_vm._e(),_c('div',{staticClass:"custom-table__checkbox"},[(_vm.setDriverId)?_c('InputCheckboxMain',{attrs:{"id":'all',"handleGetId":_vm.setDriverId,"arrayId":_vm.driverChooseAll}}):_vm._e()],1)],2),_c('div',{staticClass:"custom-table__body"},_vm._l((_vm.prepareData),function(row,index){return _c('div',{key:index,staticClass:"custom-table__row",style:(_vm.getRowStyle),on:{"click":function($event){_vm.handleChoose ? _vm.handleChoose(row) : function () {}}}},[(_vm.isNumbered)?_c('div',{staticClass:"custom-table__row-item custom-table__number-of-item"},[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),_vm._l((row),function(item,key){return _c('div',{key:key,class:[
            'custom-table__row-item',
            !_vm.isElementShowed(key) && 'custom-table__none-item',
            _vm.onCustomStyleColumn.some(function (column) { return column.columnName === key; }) &&
              _vm.onCustomStyleColumn.find(function (column) { return column.columnName === key; }).className ],on:{"click":function($event){_vm.onActionColumn.some(function (column) { return column.columnName === key; })
              ? _vm.handleActionOnClick(
                  _vm.onActionColumn.find(function (column) { return column.columnName === key; }),
                  row
                )
              : _vm.booleanSwitcher.some(function (column) { return column.columnName === key; })
              ? _vm.handleActionOnClick(
                  _vm.booleanSwitcher.find(function (column) { return column.columnName === key; }),
                  row
                )
              : function () {}}}},[(_vm.isElementShowed(key))?[(_vm.isImage(key))?_c('div',[_c('img',{attrs:{"src":item}})]):(_vm.isSwitcher(key))?[_c('div',{class:[item ? 'switcher' : 'switcher switcher--disabled']},[_vm._v(" "+_vm._s(item ? "yes" : "no")+" ")])]:(key === 'status' && _vm.name === 'order')?_c('div',{class:[
                item.key === 'completed' ||
                item.key === 'completed_by_driver' ||
                item.key === 'completed_by_client' ||
                item.key === 'completed_time_out' ||
                item.key === 'completed_by_dispatcher'
                  ? ''
                  : item.key === 'placed' ||
                    item.key === 'active' ||
                    item.key === 'set' ||
                    item.key === 'today' ||
                    item.key === 'suggesting' ||
                    item.key === 'waiting_dispatcher' ||
                    item.key === 'moderated_dispatcher'
                  ? 'green'
                  : 'red' ]},[_vm._v(" "+_vm._s(item.title)+" ")]):(key === 'free' && _vm.name === 'driver')?_c('div',{class:[item ? 'green' : 'red']},[_vm._v(" "+_vm._s(item ? "Свободен" : "Занят")+" ")]):(key === 'driver_last_name' && _vm.name === 'order')?_c('div',[_c('router-link',{staticClass:"custom-table__link",attrs:{"to":("/driverDetail/" + (row.driver_id))}},[_vm._v(_vm._s(item))])],1):_c('div',[_vm._v(_vm._s(item))])]:_vm._e()],2)}),(_vm.isActionBlockVisible)?_c('div',{ref:"actionBlock",refInFor:true,staticClass:"custom-table__action-block"},[(
              _vm.handleAssignDriver &&
              (row.status.key === 'placed' ||
                row.status.key === 'set' ||
                row.status.key === 'today' ||
                row.status.key === 'suggesting')
            )?_c('div',{staticClass:"custom-table__icon"},[_c('Driver',{on:{"click":function($event){return _vm.handleAssignDriver(row)}}})],1):_vm._e(),(_vm.handleCopy)?_c('div',{staticClass:"custom-table__icon"},[_c('Eye',{on:{"click":function($event){return _vm.handleCopy(row)}}})],1):_vm._e(),(
              _vm.name == 'order'
                ? _vm.handleEdit &&
                  (row.status.key === 'placed' ||
                    row.status.key === 'active' ||
                    row.status.key === 'set' ||
                    row.status.key === 'today' ||
                    row.status.key === 'suggesting' ||
                    row.status.key === 'expired' ||
                    row.status.key === 'waiting_dispatcher' ||
                    row.status.key === 'moderated_dispatcher')
                : _vm.handleEdit && _vm.ifHandleEdit
            )?_c('div',{staticClass:"custom-table__icon"},[_c('Pencil',{on:{"click":function($event){return _vm.handleEdit(row)}}})],1):_vm._e(),(_vm.handleDelete && _vm.ifHandleDelete)?_c('div',{staticClass:"custom-table__icon"},[_c('Basket',{on:{"click":function($event){return _vm.handleDelete(row)}}})],1):_vm._e(),(
              _vm.name == 'order'
                ? _vm.handleCancel &&
                  (row.status.key === 'placed' ||
                    row.status.key === 'active' ||
                    row.status.key === 'set' ||
                    row.status.key === 'today' ||
                    row.status.key === 'suggesting' ||
                    row.status.key === 'waiting_dispatcher' ||
                    row.status.key === 'moderated_dispatcher')
                : _vm.handleCancel
            )?_c('div',{staticClass:"custom-table__icon"},[_c('Cancel',{on:{"click":function($event){return _vm.handleCancel(row)}}})],1):_vm._e(),(_vm.handleRestore && _vm.ifHandleRestore)?_c('div',{staticClass:"custom-table__icon"},[_c('Restore',{on:{"click":function($event){return _vm.handleRestore(row)}}})],1):_vm._e(),(_vm.handleButton)?_c('div',{staticClass:"custom-table__button"},[_c('ButtonMain',{nativeOn:{"click":function($event){return _vm.handleButton(row)}}},[_vm._v("Назначить")]),(_vm.setDriverId)?_c('InputCheckboxMain',{attrs:{"id":row.id,"handleGetId":_vm.setDriverId,"arrayId":_vm.driverChoose}}):_vm._e()],1):_vm._e()]):_vm._e()],2)}),0)]):_vm._e(),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }